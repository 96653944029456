@tailwind base;
@tailwind components;
@tailwind utilities;

.galleryImage {
  @apply h-full w-full object-cover transition duration-500 ease-in-out transform hover:scale-125 cursor-pointer;
}

.small.square {
  @apply col-span-1 row-span-1;
}

.medium.square {
  @apply col-span-2 row-span-2;
}

.large.square {
  @apply col-span-3 row-span-3;
}

.small.tall {
  @apply col-span-1 row-span-2;
}

.medium.tall {
  @apply col-span-2 row-span-3;
}

.large.tall {
  @apply col-span-3 row-span-4;
}

.small.long {
  @apply col-span-2 row-span-1;
}

.medium.long {
  @apply col-span-3 row-span-2;
}

.large.long {
  @apply col-span-4 row-span-3;
}

.outerItem {
  aspect-ratio: 1;
}

#profiles > div {
  @apply px-2 pt-2 pb-4 border border-blue-900 border-opacity-50 rounded-2xl shadow-lg bg-blue-200;
  border-top-left-radius: 40px;
}
